// default light theme

// for boxed-layout
html,
body,
.app-header {
    background-color: $body-bg;
}

.app-page-container .app-content-wrapper {
    background-color: $page_bg;
}
@media only screen and (max-width: $l_screen_sm_max) {
    .app-page-container {
        background-color: $page_bg;
    }
}

